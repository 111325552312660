import { createSlice } from '@reduxjs/toolkit';
import { profileApi } from '@shared/api/profile';
import { userApi } from '@shared/api/current-user';
import { defineProfiles, getPaymentFormFields } from '../lib';
const SliceName = 'user';
const initialState = {
    paymentMethodFormFields: null,
    paymentMethodFormStep: null,
    paymentMethodFormType: null,
    isPaymentFormOpen: false,
};
const userSlice = createSlice({
    name: SliceName,
    initialState,
    reducers: {
        openPaymentMethodForm: (state, { payload }) => {
            state.paymentMethodFormStep = 0;
            state.paymentMethodFormFields = getPaymentFormFields({
                formType: payload.type,
                strategy: payload.strategy,
                plan: payload.plan,
                currentPlan: payload.currentPlan,
                defaultStep: payload.defaultStep,
            });
            state.paymentMethodFormType = payload.type;
            state.isPaymentFormOpen = true;
        },
        updatePaymentMethodForm: (state, { payload }) => {
            state.paymentMethodFormFields = Object.assign({}, payload);
        },
        setPaymentMethodFormStep: (state, { payload }) => {
            state.paymentMethodFormStep = payload;
        },
        closeSidebar: (state) => {
            state.paymentMethodFormFields = null;
            state.paymentMethodFormStep = null;
            state.paymentMethodFormType = null;
            state.isPaymentFormOpen = false;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(userApi.endpoints.getMe.matchFulfilled, (state, { payload }) => {
            var _a;
            const unactiveProfile = ((_a = payload.profiles) === null || _a === void 0 ? void 0 : _a.find(({ id }) => id !== payload.activeProfileId)) || null;
            const { clientProfile, traderProfile, } = defineProfiles(payload.profiles);
            state.user = {
                id: payload.id,
                isSuperuser: false,
                email: payload.email,
                canSwitchProfile: payload.profiles.length > 1,
                activeProfileId: payload.activeProfileId,
                unactiveProfileId: (unactiveProfile === null || unactiveProfile === void 0 ? void 0 : unactiveProfile.id) || null,
                unactiveProfile,
                profiles: payload.profiles,
                clientProfile,
                traderProfile,
                totalAssetsUsd: parseFloat(payload.totalAssetsUsd),
                totalAssetsUsdt: parseFloat(payload.totalAssetsUsdt),
            };
        });
        builder.addMatcher(profileApi.endpoints.createProfile.matchFulfilled, (state, { payload }) => {
            if (!state.user) {
                return;
            }
            if (payload.type === 'TRADER') {
                state.user.traderProfile = payload;
            }
            if (payload.type === 'CLIENT') {
                state.user.clientProfile = payload;
            }
            state.user.canSwitchProfile = true;
        });
    },
});
export { SliceName };
export const { closeSidebar, openPaymentMethodForm, setPaymentMethodFormStep, updatePaymentMethodForm, } = userSlice.actions;
export const userReducer = userSlice.reducer;
